<script setup>
import { computed, ref } from 'vue'

const props = defineProps({
  workbook: {
    required: true
  },
  supportedFields: {
    required: true
  },
  keySet: {
    required: true,
    type: Array
  }
})

const selectedSheet = defineModel('selectedSheet', { required: true })
const columnsMapToField = defineModel('columnsMapToField', { required: true, type: Object })
const keyField = defineModel('keyField', { required: true, type: String })

const displaySheetChooser = ref(false)
const chooseSet = computed(() =>
  props.supportedFields.map((field) => {
    return {
      value: field.field,
      text: field.header
    }
  })
)

if (Object.keys(props.workbook.Sheets).length === 1) {
  // We only have one Sheet so default to that
  selectedSheet.value = props.workbook.SheetNames[0]
} else {
  displaySheetChooser.value = true
}
</script>

<template>
  <div>
    <CardSection v-if="displaySheetChooser">
      <template #title> Choose the sheet to import..</template>
      <CardList>
        <CardListField v-for="(sheet, index) in workbook.SheetNames" :key="index">
          <span>{{ sheet }}</span>
          <div>
            <Checkbox v-model="selectedSheet" :selectedValue="sheet" class="radio info" />
          </div>
        </CardListField>
      </CardList>
    </CardSection>

    <CardSection v-if="Object.keys(columnsMapToField).length">
      <template #title> Map spreadsheet columns to item fields </template>
      <CardList>
        <CardListField
          v-for="(mapTo, column) in columnsMapToField"
          :class="{
            warning: !columnsMapToField[column]
          }"
          :key="column"
        >
          <span>
            {{ column }}
          </span>

          <Choose placeholder="Ignore" :staticSet="chooseSet" v-model="columnsMapToField[column]" />
        </CardListField>
      </CardList>

      <slot name="warning"></slot>

      <CardList class="mt-3">
        <CardListField>
          <span>
            <Help>
              <template #before> Key field </template>
              <p>
                Choose a field that will serve as the key to check for the same previously imported
                item. When importing, we check your existing database for items to see if the value
                of the key field already exists. If it does, the values from the import will be
                integrated into the existing item.
              </p>
            </Help>
          </span>

          <Choose v-model="keyField" :staticSet="keySet" />
        </CardListField>
      </CardList>
    </CardSection>
  </div>
</template>

<style scoped lang="scss"></style>
