import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { md5 } from '@/../lib/hashing'

export function useImporter(options) {
  const store = useStore()

  const items = ref([])
  const dirtyItemIndexes = ref([])
  const searchPhrase = ref('')
  const importFolder = ref(`company-${store.state.session.company.company_id}`)

  const importId = computed(() => {
    return md5(
      `${_.uniqueId()}${Date.now()}${store.state.session.user.user_id}${store.state.session.company.company_id}`
    )
  })

  const pagedItems = computed(() => {
    const lc = searchPhrase.value.toLowerCase()
    const indexed = items.value.map((item, index) => ({ ...item, index }))
    const filtered = lc
      ? indexed.filter((vi) => !!JSON.stringify(vi).toLowerCase().includes(lc))
      : indexed

    return _.chunk(filtered, options.perPage)
  })

  async function getImportFolder() {
    const parentFolder = `company-${store.state.session.company.company_id}`

    const importFolderName = 'Item Imports'
    const { set } = await store.dispatch('File/search', {
      filters: {
        file_name: importFolderName,
        parent_file_id: parentFolder
      }
    })

    if (set.length) return set[0]

    const { object } = await store.dispatch('File/createFolder', {
      selected: [
        {
          parent_file_id: parentFolder,
          file_name: importFolderName
        }
      ]
    })

    return object
  }

  async function removeItem(itemIndex) {
    items.value.splice(itemIndex, 1)
    return true
  }

  async function setItemField(index, field, value) {
    dirtyItemIndexes.value = _.uniq([index, ...dirtyItemIndexes.value])
    if (items.value[index]) items.value[index][field] = value
  }

  function reset() {
    items.value = []
    searchPhrase.value = ''
    dirtyItemIndexes.value = []
  }

  onMounted(() => {
    importFolder.value = getImportFolder().file_id
  })

  return {
    items,
    searchPhrase,
    importId,
    importFolder,
    getImportFolder,
    pagedItems,
    removeItem,
    setItemField,
    reset
  }
}
