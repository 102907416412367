<script setup>
import FileUpload from 'primevue/fileupload'
import { useStore } from 'vuex'
import { ref } from 'vue'

const store = useStore()

const uploader = ref()

const props = defineProps({
  templateDownloader: {
    required: true,
    type: Function
  },
  exporter: {
    required: false,
    type: Function
  },
  uploadHandler: {
    required: true,
    type: Function
  }
})

async function selectFile(event) {
  try {
    await props.uploadHandler(event)
  } catch (e) {
    await store.dispatch('alert', {
      error: true,
      message: e.message
    })
    uploader.value.files = null
  }
}
</script>

<template>
  <FileUpload ref="uploader" @select="selectFile" custom-upload>
    <template #header="{ chooseCallback }">
      <div class="flex flex-row justify-between w-full">
        <div class="flex gap-2">
          <Btn severity="tertiary" size="lg" :action="chooseCallback">
            <template #icon>
              <font-awesome-icon icon="file-xls" class="text-success" />
            </template>
            <span class="hidden md:inline-block">Upload file</span>
          </Btn>
        </div>
        <div class="flex gap-2">
          <Btn severity="tertiary" size="lg" @click="templateDownloader">
            <template #icon>
              <font-awesome-icon icon="file-xls" class="text-success" />
            </template>
            <span class="hidden md:inline-block">Download template..</span>
          </Btn>
          <Btn v-if="exporter" severity="tertiary" size="lg" :action="exporter">
            <template #icon>
              <font-awesome-icon icon="file-xls" class="text-success" />
            </template>
            <span class="hidden md:inline-block">Download current items..</span>
          </Btn>
        </div>
      </div>
    </template>
    <template #empty>
      <div class="flex items-center justify-center flex-col">
        <font-awesome-icon
          icon="upload"
          size="4x"
          fixed
          class="border-2 rounded-full p-8 text-muted-color"
        />
        <p class="mt-6 mb-0">Drag and drop your spreadsheet to upload.</p>
      </div>
    </template>
  </FileUpload>
</template>

<style scoped lang="scss"></style>
