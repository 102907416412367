import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import Client from '@/../imports/api/schemas/Client'
import CostType from '@/../imports/api/schemas/CostType'
import Vendor from '@/../imports/api/schemas/Vendor'

export function useFields(schemaType) {
  const store = useStore()

  const clientFields = ref([
    {
      header: 'Client id',
      desc: 'Your item id (if you have it already)',
      field: 'client_id',
      keywords: 'id client_id',
      example: 'EXAMPLE: 15123'
    },
    {
      header: 'Client owner',
      desc: 'Id of client owner',
      field: 'client_owner',
      keywords: 'owner',
      example: 'EXAMPLE: 15123',
      filters: { company_ids: `INSET${store.state.session.company.company_id}` }
    },
    {
      header: 'Lead source',
      desc: 'Id of lead source',
      field: 'lead_source_id',
      keywords: 'lead source marketing',
      example: 'EXAMPLE: 1234'
    },
    {
      header: 'Full name (first and last)',
      desc: `Use this field if you have one field with your clients full name.
          If you have first and last name separated, use the separate fields instead. One of either full name,
          first name or last name is required.`,
      field: 'user_name',
      keywords: 'full name',
      example: 'EXAMPLE: Jane Smith',
      showInTable: false
    },
    {
      header: 'First name',
      desc: 'Your clients first name',
      field: 'user_fname',
      keywords: 'first given',
      example: 'EXAMPLE: Jane',
      required: true
    },
    {
      header: 'Last name',
      desc: 'Your clients last name',
      field: 'user_lname',
      keywords: 'last surname family',
      example: 'EXAMPLE: Smith',
      required: true
    },
    {
      header: 'Company name',
      desc: 'Use this if your company is commercial.',
      field: 'company_name',
      keywords: 'company legal name customer',
      example: 'EXAMPLE: Smith Co Ltd.'
    },
    {
      header: 'Email',
      desc: 'RECOMMENDED: If you have your clients email, enter it. You will not be able to send them a quote without it.',
      field: 'user_email',
      keywords: 'email',
      format: 'email',
      example: 'EXAMPLE: jane@smithco.com'
    },
    {
      header: 'Accounting/AP Email (commercial only)',
      desc: 'If your client has an accounting email for invoices, put it here.',
      field: 'company_accounting_email',
      keywords: 'email',
      format: 'email',
      example: 'EXAMPLE: ap@smithco.com'
    },
    {
      header: 'Phone',
      field: 'user_phone',
      keywords: 'phone primary office main',
      example: 'EXAMPLE: 999-999-9999'
    },
    {
      header: 'Extension',
      field: 'user_phone_ext',
      keywords: 'extension',
      example: 'EXAMPLE: 2'
    },
    {
      header: 'Alternate phone',
      field: 'user_phone_alt',
      keywords: 'alt alternate secondary phone',
      example: 'EXAMPLE: 999-999-9999'
    },
    {
      header: 'Address',
      desc: `Use this field if you have one column that holds your clients full street address, city, postal/zip, state/prov, country.
          If you have all those fields separate, use the separate fields instead.`,
      field: 'full_address',
      keywords: 'address note description explanation details',
      example: 'EXAMPLE: Unit 123, 321 Mountain Avenue, New City, Kansas, 33333',
      showInTable: false
    },
    {
      header: 'Street address',
      field: 'user_address',
      keywords: 'street address',
      example: 'EXAMPLE: 123 pine street'
    },
    {
      header: 'Postal/zip code',
      field: 'user_postal',
      keywords: 'zip postal code',
      example: 'EXAMPLE: 90210 or T2G 0X2'
    },
    {
      header: 'Suite/unit number',
      field: 'user_suite',
      keywords: 'unit suite po box',
      example: 'EXAMPLE: 456A'
    },
    {
      header: 'City',
      desc: 'Defaults to your city if not provided',
      field: 'user_city',
      keywords: 'city region metro',
      example: 'EXAMPLE: Vancouver'
    },
    {
      header: 'State/province',
      desc: 'Defaults to your province if not provided',
      field: 'province_name',
      inputField: 'province_id',
      keywords: 'province region canton state',
      example: 'EXAMPLE: California'
    },
    {
      header: 'Country',
      desc: 'Defaults to your country if not provided',
      field: 'country_name',
      inputField: 'country_id',
      keywords: 'province region canton state',
      example: 'EXAMPLE: United States'
    }
  ])
  const itemFields = ref([
    {
      header: 'Item id',
      desc: 'The item_id of an existing item. Leave blank for new items.',
      required: false,
      field: 'cost_type_id',
      keywords: 'id cost_type_id',
      example: 'co_abc123'
    },
    {
      header: 'Item name',
      desc: 'Name your item',
      required: true,
      field: 'cost_type_name',
      keywords: 'name title item label',
      example: 'Oak hardwood floor install',
      frozen: true
    },
    {
      header: 'Category',
      desc: "Which category to store this item in. If it doesn't exist in your company, it will be created.",
      required: false,
      field: 'parent_cost_type_id',
      keywords: 'item category organization',
      example: 'My Category',
      guess: true,
      suggest: true,
      inTemplate: true,
      filters: {
        company_id: store.state.session.scope.company || 'NULL',
        cost_type_is_parent: 1
      },
      order: [['cost_type_time_created', 'desc']]
    },
    {
      header: 'Construction stage',
      desc: 'The construction stage that this item is in.',
      required: true,
      field: 'stage_id',
      keywords: 'item type category construction stage trade type',
      example: 'Windows',
      guess: true,
      inTemplate: true
    },
    {
      header: 'Unit type',
      desc: '',
      required: true,
      field: 'unit_of_measure_id',
      keywords: 'unit square foot sq ft cubic m metre meter each per qty unit',
      suggest: true,
      guess: true,
      example: 'ft2',
      hint: '(each, ft, ft2, ft3, yd, yd2, yd3, m, m2, m3, mm, mm2, mm3)'
    },
    {
      header: 'Subcontracted item?',
      desc: 'Is the item subcontracted?',
      required: true,
      field: 'cost_type_is_subcontracted',
      type: 'toggle',
      keywords: 'use vendor use contractor sub out',
      example: '0',
      hint: '(1 = true or 0 = false)'
    },
    {
      header: 'Materials included?',
      desc: 'Does the item have a material cost?',
      required: true,
      field: 'cost_type_has_materials',
      type: 'toggle',
      keywords: 'materials equipment vendor subcontractor has Has materials cost',
      example: '1',
      hint: '(1 = true or 0 = false)'
    },
    {
      header: 'Vendor',
      desc: '',
      required: false,
      field: 'vendor_id',
      keywords: 'vendor supplier sub contractor',
      createIfNotFound: false,
      guess: true,
      inTemplate: true,
      hint: '(material supplier or subcontractor)'
    },
    {
      header: 'Materials Waste Factor',
      desc: '',
      required: false,
      field: 'cost_type_material_waste_factor_net',
      keywords: 'material waste factor net',
      example: '0.15',
      hint: '(between 0 and 1)'
    },
    {
      header: 'Material purchase unit',
      desc: 'Enter the type of measurement you use for purchase this material from the store or your supplier',
      required: false,
      field: 'purchase_unit_of_measure_id',
      keywords: 'material purchase unit',
      example: 'box',
      hint: '(each or count, ft, ft2, ft3, m, m2, m3, yd, yd2, yd3, mm, mm2, mm3, lbs, kg)'
    },
    {
      header: 'Material qty per purchase unit',
      desc: '',
      required: false,
      field: 'cost_type_materials_purchase_qty_per_unit',
      keywords: 'material price quantity per unit',
      example: '20.15'
    },
    {
      header: 'Labor included?',
      desc: 'Does the item have a labor cost?',
      required: true,
      field: 'cost_type_has_labor',
      type: 'toggle',
      keywords: 'labour labor includes labor in house workers',
      example: '1',
      hint: '(1 = true or 0 = false)'
    },
    {
      header: 'Labor type',
      desc: 'The ID of the labor type you want to associate to this item',
      required: false,
      field: 'labor_type_id',
      keywords: 'labor rate type hourly',
      example: 'Painter'
    },
    {
      header: 'Hours to complete per unit',
      desc: 'Time required to complete 1 unit (ex. ft) of this item, in decimal hours ONLY, ie: 0.5 = 30 minutes',
      required: false,
      field: 'cost_type_hours_per_unit',
      keywords: 'hours time per unit',
      example: '0.5'
    },
    {
      header: 'Materials cost per unit',
      desc: 'Enter a number for materials costs (can be 0)',
      required: false,
      field: 'cost_matrix_materials_cost_net',
      keywords: 'material sub contractor subcontractor equipment vendor costs',
      example: '12.55'
    },
    {
      header: 'Labor cost per unit',
      desc: 'Enter a number if this item includes labor costs',
      required: false,
      field: 'cost_matrix_labor_cost_net',
      keywords: 'labor employee labour worker in-house in house salary wages',
      example: '8.52'
    },
    {
      header: 'Combined cost per unit',
      desc: 'Enter a total cost for this item',
      required: false,
      field: 'cost_matrix_aggregate_cost_net',
      keywords: 'combined aggregate cost total',
      example: '21.07'
    },
    {
      header: 'Markup',
      desc: 'Enter a markup for this item. MUST be larger than 1.',
      required: false,
      field: 'cost_matrix_markup_net',
      keywords: 'retail rate multiplier markup',
      example: '1.43'
    },
    {
      header: 'Client price per unit',
      desc: 'What you charge your customer, per unit',
      required: false,
      field: 'cost_matrix_rate_net',
      keywords: 'price customer price retail rate',
      example: '30.13'
    },
    {
      header: 'Quantity equation',
      desc: 'Create a formula to determine the quantity of this item using variables (fa, iwp, etc)',
      required: false,
      field: 'cost_type_qty_equation',
      keywords: 'equation formula link dimension code',
      guess: false,
      suggest: true,
      example: 'fa'
    },
    {
      header: 'Minimum quantity',
      desc: 'Set a minimum quantity for this item, if the quantity falls below that value it will be bumped up to the minimum.',
      required: false,
      field: 'cost_type_minimum_qty_net',
      keywords: 'minimum min qty quantity purchase',
      example: '100'
    },
    {
      header: 'SKU',
      desc: 'If you have a SKU enter it',
      required: false,
      field: 'cost_type_sku',
      keywords: 'sku item id reference ref unique code',
      example: "HW344 (you can skip this field and any fields you don't have, or don't know)"
    },
    {
      header: 'Item description',
      desc: 'Describe your item',
      required: false,
      field: 'cost_type_desc',
      keywords: 'note description explanation details',
      example:
        'Install and supply oak 8" plank hardwood floor, tongue and groove, glued and nailed. Not including subfloor/repair.'
    },
    {
      header: 'Internal notes',
      desc: 'Internal notes for an item',
      required: false,
      field: 'cost_type_production_notes',
      keywords: 'production notes internal',
      example: 'A sentence about using the item.'
    },
    {
      header: 'CSI code',
      desc: 'The CSI code of the item',
      required: false,
      field: 'csi_code_id',
      keywords: 'id csi_code_id',
      example: '001000',
      guess: true,
      inTemplate: true,
      createIfNotFound: false
    }
  ])
  const vendorFields = ref([
    {
      header: 'Email',
      desc: 'RECOMMENDED: If you have your vendors email, enter it.',
      field: 'user_email',
      keywords: 'email',
      format: 'email',
      example: 'EXAMPLE: jane@smithco.com'
    },
    {
      header: 'Company name',
      desc: 'Your vendor company name',
      field: 'company_name',
      keywords: 'company legal name customer',
      example: 'EXAMPLE: Smith Co Ltd.'
    },
    {
      header: 'Address',
      desc: `Use this field if you have one column that holds your vendors full street address, city, postal/zip, state/prov, country.
          If you have all those fields separate, use the separate fields instead.`,
      field: 'full_address',
      keywords: 'address note description explanation details',
      example: 'EXAMPLE: Unit 123, 321 Mountain Avenue, New City, Kansas, 33333',
      showInTable: false
    },
    {
      header: 'Street address',
      desc: '',
      field: 'company_address',
      keywords: 'street address',
      example: 'EXAMPLE: 123 pine street'
    },
    {
      header: 'Postal/zip code',
      desc: '',
      field: 'company_postal',
      keywords: 'zip postal code',
      example: 'EXAMPLE: 90210 or T2G 0X2'
    },
    {
      header: 'Suite/unit number',
      desc: '',
      field: 'company_suite',
      keywords: 'unit suite po box',
      example: 'EXAMPLE: 456A'
    },
    {
      header: 'City',
      desc: 'Defaults to your city if not provided',
      field: 'company_city',
      keywords: 'city region metro',
      example: 'EXAMPLE: Vancouver'
    },
    {
      header: 'State/province',
      desc: 'Defaults to your province if not provided',
      field: 'province_name',
      inputField: 'province_id',
      keywords: 'province region canton state',
      example: 'EXAMPLE: California'
    },
    {
      header: 'Country',
      desc: 'Defaults to your country if not provided',
      field: 'country_name',
      inputField: 'country_id',
      keywords: 'province region canton state',
      example: 'EXAMPLE: United States'
    },
    {
      header: 'Phone',
      desc: '',
      field: 'company_phone',
      keywords: 'phone primary office main',
      example: 'EXAMPLE: 999-999-9999'
    },
    {
      header: 'Contact Full name (first and last)',
      desc: `Use this field if you have one field with your vendors full name.
          If you have first and last name separated, use the separate fields instead. One of either full name,
          first name or last name is required.`,
      field: 'user_name',
      keywords: 'full name',
      example: 'EXAMPLE: Jane Smith',
      showInTable: false
    },
    {
      header: 'Contact First name',
      desc: 'Your vendors first name',
      field: 'user_fname',
      keywords: 'first given',
      example: 'EXAMPLE: Jane',
      required: true
    },
    {
      header: 'Contact Last name',
      desc: 'Your vendors last name',
      field: 'user_lname',
      keywords: 'last surname family',
      example: 'EXAMPLE: Smith',
      required: true
    }
  ])
  const allFields = ref()
  const supportedFields = ref()

  switch (schemaType) {
    case 'client':
      allFields.value = Client.fields
      supportedFields.value = clientFields.value
      break
    case 'item':
      allFields.value = CostType.fields
      supportedFields.value = itemFields.value
      break
    case 'vendor':
      allFields.value = Vendor.fields
      supportedFields.value = vendorFields.value
      break
  }

  const visibleSupportedFields = computed(() => {
    return supportedFields.value.filter(
      (field) => (field.inputField || field.field) in allFields.value && field.showInTable !== false
    )
  })

  return {
    allFields,
    supportedFields,
    visibleSupportedFields
  }
}
