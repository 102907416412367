<script setup>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Textarea from 'primevue/textarea'
import InputNumber from 'primevue/inputnumber'
import Dropdown from 'primevue/dropdown' // DataTAble Paginator uses this, and we need to...
Dropdown.compatConfig = { MODE: 3 } // Because it uses Vue 3 `v-model`

const props = defineProps({
  schema: {
    required: true,
    type: String
  },
  columns: {
    required: true,
    type: Array
  }
})

const items = defineModel('items', { required: true, type: Array })

function onCellEditComplete(event) {
  const { field, newValue, index } = event
  items.value[index][field] = newValue
}

function fieldIsChoosable(column) {
  // Don't allow primary keys to be choosable
  if (fieldIsPrimary(column)) return false
  return /_(?:ids?|owner)$/.test(column?.inputField || column?.field)
}

function fieldIsToggleable(column) {
  return column.type === 'toggle'
}

function fieldIsPrimary(column) {
  return column.field === `${props.schema}_id`
}

function fieldIsExtended(column) {
  return !!column.extended
}

function fieldIsEditable(column) {
  return !fieldIsChoosable(column) && !fieldIsPrimary(column) && !fieldIsToggleable(column)
}

function shouldReturnArray(column) {
  return /_ids$/.test(column.inputField || column.field)
}
</script>

<template>
  <div class="flex grow overflow-y-hidden">
    <DataTable
      :value="items"
      stripedRows
      show-gridlines
      paginator
      :rows="20"
      :rowsPerPageOptions="[20, 50, 100, 200]"
      edit-mode="cell"
      @cell-edit-complete="onCellEditComplete"
      scrollable
      scroll-height="flex"
    >
      <Column
        class="min-w-40 max-w-80 truncate"
        v-for="col of columns"
        :key="col.field"
        :field="col.field"
        :header="col.header"
        :frozen="col.frozen"
      >
        <template #header v-if="col.hint">
          <div class="order-last ml-auto pl-2" v-tooltip.top="col.hint">
            <font-awesome-icon icon="circle-question" />
          </div>
        </template>
        <template v-if="fieldIsExtended(col)" #body="{ data, field, index }">
          <template v-if="data && field">
            <slot
              name="extended"
              v-bind="{
                item: data[field],
                data,
                field,
                index,
                editCallback: onCellEditComplete
              }"
            ></slot>
          </template>
        </template>
        <template
          v-else-if="fieldIsChoosable(col) || fieldIsToggleable(col)"
          #body="{ data, field, index }"
        >
          <template v-if="data && field">
            <Choose
              v-if="fieldIsChoosable(col)"
              :schema="`${schema}:${col.inputField || field}`"
              :return-array="shouldReturnArray(col)"
              :allow-deselect="!col.required"
              :filters="col.filters ?? {}"
              :order="col.order"
              @input="(newValue) => onCellEditComplete({ field, index, newValue })"
              :value="data[field]"
            />
            <Checkbox
              v-else-if="fieldIsToggleable(col)"
              :value="data[field]"
              @input="(newValue) => onCellEditComplete({ field, index, newValue })"
            />
          </template>
        </template>
        <template v-if="fieldIsEditable(col)" #editor="{ data, field }">
          <template v-if="data && field">
            <!-- Max decimals of 16 because yeah sure, why not? -->
            <InputNumber
              v-if="typeof data[field] === 'number'"
              v-model:model-value="data[field]"
              :max-fraction-digits="16"
              autofocus
            />
            <Textarea
              v-else
              class="w-full"
              v-model:model-value="data[field]"
              auto-resize
              autofocus
            />
          </template>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<style scoped lang="scss"></style>
