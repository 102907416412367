import { useStore } from 'vuex'
import Objects from '@/../imports/api/Objects'
import Fuse from 'fuse.js'

export function useGuesser() {
  const store = useStore()
  const { getConstructor } = Objects

  async function getList(options) {
    const {
      type,
      phrase,
      fetchAll = true,
      cache = true,
      filters = null,
      force = false,
      exact = false,
      field = null
    } = options

    const key = `guesser-${type}-${fetchAll ? 'all' : phrase}`
    let list = cache ? c.getCacheItem(key, type, store.state.session) : null
    // before returning cache as result make sure item is in cache
    const searchCache = list && exact ? list.filter((i) => i[field] === phrase) : null
    if (force || !list || !list.length || (searchCache && searchCache.length === 0)) {
      const exactMixin = exact
        ? {
            [field]: `${phrase}`
          }
        : {}

      const { set } = await store.dispatch(`${c.titleCase(type)}/search`, {
        ...(!exact ? { searchPhrase: fetchAll ? null : phrase } : {}),
        filters: {
          ...(filters || {}),
          ...exactMixin
        }
      })

      list = set
      if (cache) c.setCacheItem(key, set, type, store.state.session)
    }

    return list
  }

  function getFieldsToSearch(type) {
    const fields = getConstructor(type).fields

    const trySuffixes = [
      ['_id', 5],
      ['_abbr', 2],
      ['_name', 4],
      ['_desc', 2],
      ['_email', 4],
      ['_phone', 2],
      ['_keywords', 2]
    ]

    const fieldKeys = Object.keys(fields)
    const exists = trySuffixes.filter((suff) => fieldKeys.includes(`${type}${suff[0]}`))

    return exists.reduce(
      (acc, suff) => [
        ...acc,
        {
          name: `${type}${suff[0]}`,
          weight: suff[1]
        }
      ],
      []
    )
  }

  async function guessByType_internal(options) {
    const {
      type,
      phrase,
      // cache = true,
      // fetchAll = true,
      // filters = {},
      // List of ids
      exclusions = [],
      // Adidiotnal fields tto search
      fields = [],
      limit = 1
    } = options

    const phraseString = String(phrase).trim()
    const list = await getList(options)

    const idField = `${type}_id`

    // First find exact match if id is found
    const foundExact = list.find((item) => String(item[idField]) === phraseString)
    if (foundExact) return foundExact[idField]

    const keys = getFieldsToSearch(type)
    const fuse = new Fuse(list, {
      minMatchCharLength: 4,
      keys: [...keys, ...fields]
    })

    const found = fuse.search(phraseString)
    const filterFound = found.filter((f) => !exclusions.includes(f.item[idField]))

    if (!filterFound.length) return null

    if (limit === 1) {
      return filterFound[0].item[idField]
    }

    const chunks = _.chunk(filterFound, limit)
    return chunks[0].map((chitem) => chitem.item[idField])
  }

  async function createIfNotFound(name, type) {
    let found = null
    try {
      const { object } = await store.dispatch(`${c.titleCase(type)}/save`, {
        object: {
          type,
          [`${type}_name`]: name
        },
        go: false
      })
      found = object
    } catch (e) {
      return null
    }

    await getList({
      type,
      force: true
    })

    if (!found) return null

    return found[`${type}_id`]
  }

  function bestGuess(phrase, type) {
    return guessByType_internal({
      phrase,
      type
    })
  }

  async function guessByType(options) {
    const foundId = await guessByType_internal(options)

    if (!foundId && options.createIfNotFound) {
      return typeof options.createIfNotFound === 'function'
        ? await options.createIfNotFound(options.phrase, options.type, options)
        : await createIfNotFound(options.phrase, options.type)
    }

    const nullIfNotFound = 'nullIfNotFound' in options ? options.nullIfNotFound : true

    return foundId || (nullIfNotFound ? null : options.phrase)
  }

  return {
    bestGuess,
    guessByType
  }
}
